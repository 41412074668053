<template>
  <div>
    <ManageNews1 />
  </div>
</template>
<script>
import ManageNews1 from "@/components/News1/ManageNews1";
export default {
  components: {
    ManageNews1,
  },
  created() {},
};
</script>c