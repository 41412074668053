<template>
  <v-form>
    <v-card width="100%" flat color="#f5f5f5">
      <v-row align="center" justify="center">
        <v-col cols="12" md="6" sm="6">
          <span
            style="
              font-family: 'IBM Plex Sans Thai';
              font-style: normal;
              font-weight: 600;
              font-size: 24px;
              line-height: 40px;
            "
          >
            ประกาศทั่วไป
          </span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" md="6" sm="6" class="text-right">
          <v-btn @click="goToCreate()" color="#2AB3A3" dark style="height: 48px"
            >+ เพิ่มประกาศทั่วไป</v-btn
          >
        </v-col>
      </v-row>
    </v-card>

    <v-card class="mt-1" width="100%" flat color="#f5f5f5">
      <v-row align="center" justify="center">
        <v-col cols="12" md="12" sm="12">
          <v-card class="pa-6" width="100%" flat>
            <v-row>
              <v-col cols="12" md="3" sm="3">
                <v-text-field
                  hide-details
                  prepend-inner-icon="mdi-magnify"
                  placeholder="ค้นหา"
                  outlined
                  dense
                  v-model="search"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="3" sm="3">
                <v-select
                  outlined
                  dense
                  :items="[
                    'สถานะทั้งหมด',
                    'เปิดใช้งาน',
                    'ปิดใช้งาน',
                    'ฉบับร่าง',
                  ]"
                  v-model="statusSearch"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-data-table
              :headers="headersNews"
              :items="items"
              :search="search"
              :items-per-page="10"
              class="elevation-1"
            >
              <template v-slot:[`item.img_url`]="{ item }">
                <v-img :src="item.img_url" width="120px" class="ma-1"></v-img>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-chip
                  :color="
                    item.status == 'เปิดใช้งาน'
                      ? '#ECFDF3'
                      : item.status == 'ฉบับร่าง'
                      ? '#F0F9FF'
                      : '#F2F4F7'
                  "
                  dark
                >
                  <span
                    v-if="item.status == 'เปิดใช้งาน'"
                    style="color: #027a48"
                  >
                    {{ item.status }}
                  </span>
                  <span
                    v-if="item.status == 'ปิดใช้งาน'"
                    style="color: #344054"
                  >
                    {{ item.status }}
                  </span>
                  <span v-if="item.status == 'ฉบับร่าง'" style="color: #026aa2">
                    {{ item.status }}
                  </span>
                </v-chip>
              </template>

              <template v-slot:[`item.updatedAt`]="{ item }">
                {{ convertDate(item.updatedAt) }}
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-row justify="center">
                  <v-icon @click="ViewNews(item)">mdi-content-copy</v-icon>
                  <v-icon @click="UpdateNews(item)" class="mx-2"
                    >mdi-pencil</v-icon
                  >
                  <v-icon @click="DeleteNews(item)">mdi-delete</v-icon>
                </v-row>
              </template>
              <template v-slot:body="props" v-if="isWindowWidthGreaterThan768">
                <draggable
                  :list="props.items"
                  tag="tbody"
                  @change="DragItems(props.items)"
                >
                  <tr v-for="(item, index) in props.items" :key="index">
                    <td v-if="isWindowWidthGreaterThan768">
                      <v-icon small class="page__grab-icon">
                        mdi-arrow-all
                      </v-icon>
                    </td>
                    <td class="text-start">
                      {{ convertDate(item.updatedAt) }}
                    </td>
                    <td class="text-start">
                      <v-img
                        :src="item.img_url"
                        width="120px"
                        class="ma-1"
                      ></v-img>
                    </td>
                    <td class="text-start">
                      {{ item.title_th }}
                    </td>
                    <td class="text-center">
                      <v-chip
                        :color="
                          item.status == 'เปิดใช้งาน'
                            ? '#ECFDF3'
                            : item.status == 'ฉบับร่าง'
                            ? '#F0F9FF'
                            : '#F2F4F7'
                        "
                        dark
                      >
                        <span
                          v-if="item.status == 'เปิดใช้งาน'"
                          style="color: #027a48"
                        >
                          {{ item.status }}
                        </span>
                        <span
                          v-if="item.status == 'ปิดใช้งาน'"
                          style="color: #344054"
                        >
                          {{ item.status }}
                        </span>
                        <span
                          v-if="item.status == 'ฉบับร่าง'"
                          style="color: #026aa2"
                        >
                          {{ item.status }}
                        </span>
                      </v-chip>
                    </td>

                    <td class="text-start">
                      <v-row justify="center">
                        <v-icon @click="ViewNews(item)"
                          >mdi-content-copy</v-icon
                        >
                        <v-icon @click="UpdateNews(item)" class="mx-2"
                          >mdi-pencil</v-icon
                        >
                        <v-icon @click="DeleteNews(item)">mdi-delete</v-icon>
                      </v-row>
                    </td>
                  </tr>
                </draggable>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-form>
</template>
<script>
import draggable from "vuedraggable";
import { Decode, Encode } from "@/services";
import moment from "moment";
export default {
  components: {
    draggable,
  },
  data() {
    return {
      statusSearch: "สถานะทั้งหมด",

      headersNews: [],
      headersNews1: [
        {
          text: "",
          value: "",
          align: "start",
        },
        {
          text: "วันที่เผยแพร่",
          value: "updatedAt",
          align: "start",
        },
        { text: "รูปปก", value: "img_url", align: "start" },
        { text: "หัวข้อ (ไทย)", value: "title_th", align: "start" },

        {
          text: "สถานะ",
          value: "status",
          align: "center",
        },
        { text: "ปุ่มจัดการ", value: "action", align: "center" },
      ],
      headersNews2: [
        {
          text: "วันที่เผยแพร่",
          value: "updatedAt",
          align: "start",
        },
        { text: "รูปปก", value: "img_url", align: "start" },
        { text: "หัวข้อ (ไทย)", value: "title_th", align: "start" },

        {
          text: "สถานะ",
          value: "status",
          align: "center",
        },
        { text: "ปุ่มจัดการ", value: "action", align: "center" },
      ],
      items: [
        // { category: "promotion", description: "สินค้าจัดโปร" },
        // { category: "discount items", description: "สินค้าลดราคา" },
        // { category: "Shock price", description: "สินค้าราคาถูก" },
        // { category: "สินค้ามาใหม่", description: "สินค้ามาใหม่" },
      ],
      search: "",
      count: 0,

      dateCreated: "",
    };
  },
  computed: {
    isWindowWidthGreaterThan768() {
      return window.innerWidth > 768;
    },
  },
  created() {
    this.getAllNews();
    document.title = "ประกาศทั่วไป - GSCM Management System";
  },

  mounted() {
    window.scrollTo(0, 0);

    if (this.isWindowWidthGreaterThan768) {
      this.headersNews = this.headersNews1;
    } else {
      this.headersNews = this.headersNews2;
    }
  },

  methods: {
    async DragItems(val) {
      this.loading = true;
      console.log("DragItems", this.items);
      console.log("beforeAPI", val);
      var user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };

      for (let i in val) {
        val[i].index = parseInt(i) + 1;
      }

      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/news/updateIndex`,
        val,
        auth
      );
      console.log("afterAPI", response);

      const response1 = await this.axios.get(
        `${process.env.VUE_APP_API}/news?type=2`,
        auth
      );
      console.log("findAllUpdateIndex", response1.data.data);
      this.items = response1.data.data;
      this.loading = false;
    },
    async getAllNews() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/news?type=2`
        // 'http://localhost:8081/categories/'
      );
      console.log("getAllNews", response.data.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
        //console.log(this.list);
      }
    },
    convertDate(val) {
      return moment(val).format("DD/MM/YYYY");
      // return moment(val).format("MM/DD/YYYY hh:mm");
    },
    UpdateNews(val) {
      localStorage.setItem("NewsData", Encode.encode(val));
      this.$router.push("EditNews1");
    },
    async ViewNews(val) {
      this.loading = true;
      this.userData = JSON.parse(
        Decode.decode(localStorage.getItem("gscmAdmin"))
      );
      const auth = {
        headers: {
          Authorization: `Bearer ${this.userData.token}`,
        },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/news/${val.id}`,
        auth
      );
      console.log("getOne", response.data.data);
      delete response.data.data.id;
      let duplicateData = response.data.data;

      await duplicateData.documentFilesNews.forEach(async (element) => {
        delete element.newId;
        delete element.id;
      });
      await duplicateData.pathPic.forEach(async (element) => {
        delete element.newId;
        delete element.id;
      });
      console.log("duplicateData", duplicateData);
      const response1 = await this.axios.post(
        `${process.env.VUE_APP_API}/news`,
        duplicateData,
        auth
      );
      console.log("response1", response1);
      console.log(response1.data.response_status);
      if (response1.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: `คัดลอกสำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
        this.getAllNews();
      } else {
        this.$swal.fire({
          icon: "error",
          text: response1.data.message,
          showConfirmButton: false,
          timer: 2000,
        });
      }
      this.loading = false;
    },
    async DeleteNews(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          var user = JSON.parse(
            Decode.decode(localStorage.getItem("gscmAdmin"))
          );
          const auth = {
            headers: { Authorization: `Bearer ${user.token}` },
          };
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/news/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllNews();
        }
      });
    },
    goToCreate() {
      this.$router.push("CreateNews1");
    },
  },
};
</script>